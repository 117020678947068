<template>
    <div>
        <div v-if="isArabic" class="marketo-form ar">
            <Typography class="title" variant="h3-display" as="h3">{{ $t('إشترك اليوم') }}</Typography>
            <Typography class="description" variant="body-display-small" as="p">{{ formDescriptionArabic }}</Typography>
            <form id="mktoForm_1014"></form>
            <div id="confirmFormCTWEngage" style="display: none">
                <strong>شكرا لك</strong>
                <p>
                    الرجاء العودة إلى رسالة في بريدكم الإلكتروني تحوي رابطاً إلى موقع كارنيغي carnegiendowment.org، حيث
                    يمكنكم تعديل موادكم المفضَّلة.
                </p>
            </div>
        </div>
        <div v-else class="marketo-form">
            <Typography class="title" variant="h3-display" as="h3">{{ $t('Subscribe Today') }}</Typography>
            <Typography class="description" variant="body-display-small" as="p">{{
                formDescriptionEnglish
            }}</Typography>
            <form id="mktoForm_1001"></form>
            <div id="confirmFormCTWEngage" style="display: none">
                <strong>Thank you!</strong>
                <p>Check your email for details on your request.</p>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    formContextData: {
        type: Object,
    },
    formDescriptionArabic: {
        type: String,
        default:
            'سوف تنقل المدوّنة أيضاً ردود فعل الخبراء تجاه الأخبار العاجلة والأحداث الآنيّة، وتشكّل منبراً لبثّ مقابلات تُجرى مع شخصيّات عامّة وسياسية، كما ستسمح بمواكبة الأبحاث الصادرة عن كارنيغي.',
    },
    formDescriptionEnglish: {
        type: String,
        default:
            'Drawing on the expertise of a team of Carnegie scholars—both in the Middle East and in Washington—Diwan offers reactions to breaking news, interviews with personalities and political figures, and updates on Carnegie research projects.',
    },
});
const { locale } = useLocale();
const isArabic = computed(() => {
    return locale._value === 'ar';
});
function formDiwanEn() {
    MktoForms2.loadForm('//813-XYU-422.mktoweb.com', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'Diwan Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'beirut' });
            form.addHiddenFields({ automatedEmailAll: 'diwanEn' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formDiwanAr() {
    MktoForms2.loadForm('//813-XYU-422.mktoweb.com', '813-XYU-422', 1014, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'Diwan Subscribe Mkto Ar' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'beirut' });
            form.addHiddenFields({ automatedEmailAll: 'diwanAr' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
onMounted(() => {
    if (locale._value === 'en') {
        formDiwanEn();
    }
    if (locale._value === 'ar') {
        formDiwanAr();
    }
});
</script>

<style lang="scss" scoped>
:deep(.marketo-form) {
    max-width: 60% !important;
    margin: 100px auto;
    text-align: center;

    .mktoForm {
        width: 100% !important;
        margin-top: 50px;

        * {
            font-weight: bold;
        }

        label#LblEmail,
        .mktoOffset {
            display: none;
        }

        .mktoFormRow {
            width: 65% !important;

            .mktoFieldDescriptor,
            .mktoFieldWrap {
                width: 100%;
            }

            @include media-query(phone) {
                width: 100% !important;
            }
        }

        .mktoField {
            font-size: 1.3em !important;
        }

        input[type='text'],
        input[type='url'],
        input[type='email'],
        input[type='tel'],
        input[type='number'],
        input[type='date'],
        textarea.mktoField,
        input.mktoEmailField,
        select.mktoField {
            font-style: italic;
            background-color: color(offblanc);
            box-shadow: 0 0 0 0;
            border-radius: 0 0 0 0;
            line-height: 2em;
            padding: 7.5px 5px 5px 15px;
            height: 50px;
            width: 100% !important;
        }

        .mktoLabel {
            font-weight: normal;

            strong {
                font-weight: bold;
            }
        }

        label {
            color: black;
            float: right !important;
            margin-left: 1px !important;
            width: 90% !important;

            em {
                font-weight: bold;
                font-style: italic;
                padding: 0;
            }

            strong {
                font-weight: bold;
                padding: 0;
            }
        }

        .mktoButtonRow {
            max-width: 25%;
            float: right;
            margin-top: -55px;

            button {
                gap: 0.8rem;
                color: rgba(var(--color-rgb-blanc-default), 0.8);
                background-color: rgb(var(--color-rgb-teal-default));
                border-radius: 5px;
                text-decoration: none;
                padding: 1.6rem 2.6rem 1.8rem;
                display: inline-flex;
                align-items: center;
                flex-wrap: wrap;
                text-align: center;
            }

            @include media-query(phone) {
                max-width: 100%;
                float: none;
                margin-top: 0;
            }
        }
    }

    #confirmFormCTWEngage {
        background-color: color(offblanc);
        margin: 50px auto 0 auto;
        padding: 30px;
        width: fit-content;
    }

    .info {
        margin-top: 30px;

        a {
            text-decoration-line: underline;
            text-decoration-thickness: 0.0833333333em;
            -webkit-text-decoration-skip: ink;
            text-decoration-skip-ink: auto;
            text-underline-offset: 0.25em;
            text-decoration-color: var(--color-anchor-underline);
        }
    }

    &.ar {
        .mktoButtonRow {
            float: left;
        }
    }
}
</style>
